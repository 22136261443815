import { useCallback, useEffect, useRef, useState } from 'react'
import { Transition } from '@headlessui/react'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { GuildIcon } from '@/atoms/Icons/GuildIcon'
import { CaptionMD } from '@/atoms/Text'
import { useGBExperiment } from '@/experimentation'
import { useLocalStorage } from '@/hooks/useLocaleStorage'
import { NearbyShowtimesCard } from './NearbyShowtimesCard'
import { TypingText } from './TypingText'

interface Props {
  name: string
  poster: string
  theatricalSlug: string
  discountCodes: [string, ...string[]]
  date: Date
  venue: {
    name: string
    address_text: string
  }
  showtime: {
    local_start_time_friendly: string
    ticket_url: string
  }
  prompt: string
  heading?: string
}

const TRANSITION_CLASSES = {
  enter: 'ease-out duration-700',
  enterFrom: 'translate-y-[200%]',
  enterTo: 'translate-y-0',
  leave: 'ease-in duration-700',
  leaveFrom: 'translate-y-0',
  leaveTo: 'translate-y-[200%]',
}

export const DismissableNearbyShowtimesAlert: React.FC<Props> = ({ theatricalSlug, heading, prompt, ...rest }) => {
  const [show, setShow] = useState(false)
  const [inVariation] = useGBExperiment('nearby_showtimes_bot', false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const {
    storedValue: dismissed,
    setStoredValue: setDismissed,
    isReady,
  } = useLocalStorage(`dismissed-nearby-showtimes-${theatricalSlug}`, false, 'boolean')

  const handleDismiss = useCallback(() => {
    setDismissed(true)
    setShow(false)
  }, [setDismissed, setShow])

  useEffect(() => {
    if (!isReady) return
    if (!dismissed) {
      // extra timeout helps with animationjankiness
      timeoutRef.current = setTimeout(() => {
        setShow(true)
      }, 1000)
    }
  }, [dismissed, isReady])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [])

  if (!inVariation || !isReady) return null

  return (
    <div className="fixed bottom-0 z-[999999] w-full">
      <div className="flex justify-center px-2 lg:justify-end lg:pr-4">
        <Transition show={show} {...TRANSITION_CLASSES}>
          <div className="relative rounded-t-xl border border-b-0 border-gray-700 bg-core-gray-900/80 p-4 backdrop-blur-lg">
            <button
              className="absolute right-2 top-0 -translate-y-1/2 translate-x-1/2 rounded-full bg-core-gray-700 p-1"
              onClick={handleDismiss}
            >
              <CloseIcon className="h-4 w-4" color="white" />
            </button>
            <div className="flex flex-col gap-3 sm:w-[400px]">
              <div className="flex animate-[fadein_0.5s_ease-out_0.7s_forwards] items-center gap-2 rounded-xl bg-core-gray-950/80 p-2 opacity-0">
                <div className="shrink-0">
                  <GuildIcon size={24} color1="white" className="animate-[scaleUpDown_1s_ease-in-out_0.5s_forwards]" />
                </div>
                <TypingText text={prompt} color="warm-02" typingSpeed={20} startDelay={700} />
              </div>

              {heading && (
                <CaptionMD color="core-gray-400" className="text-pretty px-3">
                  {heading}
                </CaptionMD>
              )}

              <NearbyShowtimesCard theatricalSlug={theatricalSlug} handleDismiss={handleDismiss} {...rest} />
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}
