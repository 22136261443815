import { ApolloQueryResult, gql as contentfulGQL } from '@apollo/client'
import axios from 'axios'
import { format } from 'date-fns'
import { ContentfulClientOptions, getContentfulClient } from '@/services/ApolloClient'
import {
  GetGuildDashboardTheatricalReleasesQuery,
  GetGuildDashboardTheatricalReleasesQueryVariables,
  GetGuildTheatricalReleasesQuery,
  GetTheatricalReleasesBySlugsQuery,
} from '@/types/codegen-contentful'
import { reportErrorToBugsnag } from '@/utils/bugsnag'

export type GetGuildTheatricalReleasesResult = NonNullable<
  GetGuildTheatricalReleasesQuery['theatricalReleasesCollection']
>['items']

export const GET_GUILD_THEATRICAL_RELEASES_QUERY = contentfulGQL/* GraphQL */ `
  query getGuildTheatricalReleases {
    theatricalReleasesCollection {
      items {
        projectSlug
        theatricalSlug
        theatricalName
        releaseDate
        verticalPosters
        horizontalPosters
        shortSynopsis
        filmDetails {
          ... on FilmDetails {
            releaseYear
          }
        }
      }
    }
  }
`

export const getGuildTheatricalReleases = async (
  opts?: ContentfulClientOptions,
): Promise<GetGuildTheatricalReleasesResult> => {
  try {
    const client = getContentfulClient({
      ...opts,
      locale: opts?.locale ?? 'en',
    })

    const { data: contentfulData } = await client.query({
      query: GET_GUILD_THEATRICAL_RELEASES_QUERY,
      fetchPolicy: 'no-cache',
    })

    return contentfulData?.theatricalReleasesCollection?.items || []
  } catch (err) {
    reportErrorToBugsnag('Failed to getAllTheatricalReleases' + (err instanceof Error && ': ' + err.toString()))
  }

  return []
}

export const GET_THEATRICALS_BY_SLUGS = contentfulGQL/* GraphQL */ `
  query getTheatricalReleasesBySlugs($theatricalSlugs: [String]) {
    theatricalReleasesCollection(
      where: { theatricalSlug_in: $theatricalSlugs }
      order: [featuredOrder_ASC, releaseDate_ASC]
    ) {
      items {
        theatricalSlug
        theatricalName
        endDate
        releaseDate
        horizontalPosters
        verticalPosters
        shortSynopsis
        projectSlug
        trailer {
          url
        }
      }
    }
  }
`

export type TheatricalReleases = NonNullable<GetTheatricalReleasesBySlugsQuery['theatricalReleasesCollection']>['items']
export type TheatricalRelease = NonNullable<
  NonNullable<GetTheatricalReleasesBySlugsQuery['theatricalReleasesCollection']>['items']
>[number]

export async function getTheatricalsBySlugs(
  theatricalSlugs: string[],
  opts?: ContentfulClientOptions,
): Promise<TheatricalReleases> {
  const contentfulClient = getContentfulClient({ ...opts, locale: opts?.locale ?? 'en' })
  const { data } = await contentfulClient.query<GetTheatricalReleasesBySlugsQuery>({
    query: GET_THEATRICALS_BY_SLUGS,
    variables: { theatricalSlugs },
  })

  return data.theatricalReleasesCollection?.items || []
}

export const GET_GUILD_DASHBOARD_THEATRICAL_RELEASES = contentfulGQL/* GraphQL */ `
  query getGuildDashboardTheatricalReleases($preview: Boolean!) {
    theatricalLandingReleaseCollection(preview: $preview) {
      items {
        sys {
          id
        }
        releaseStatus
        linkUrl
        dateDisplayOption
        contentCatalogId
        filmDetails {
          filmName
          theatricalReleaseDate
          releaseYear
          moviePoster
          trailerSourceUrl
        }
      }
    }
  }
`

type QueryResult = ApolloQueryResult<GetGuildDashboardTheatricalReleasesQuery>
export type GuildDashboardTheatricalRelease = NonNullable<
  QueryResult['data']['theatricalLandingReleaseCollection']
>['items'][number]

export async function getGuildDashboardTheatricalReleases(preview: boolean) {
  return getContentfulClient({
    preview,
  }).query<GetGuildDashboardTheatricalReleasesQuery, GetGuildDashboardTheatricalReleasesQueryVariables>({
    query: GET_GUILD_DASHBOARD_THEATRICAL_RELEASES,
    variables: {
      preview,
    },
  })
}

const createTheatricalMonolithRestApi = (version = 'v1') => {
  return axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_THEATRICAL_MONOLITH_API_URL ?? ''}/${version}`,
  })
}

interface NearbyShowtimesInput {
  theatricalSlug: string
  uuid: string
  numberOfVenues?: number
  startDate?: string
  numberOfDays?: number
}

export interface NearbyShowtimesResponse {
  venues: {
    address_text: string
    name: string
    showtimes_by_day: {
      date: string
      showtimes: {
        local_start_time_friendly: string
        ticket_url: string
      }[]
    }[]
  }[]
}

export const getNearbyShowtimes = async (
  { theatricalSlug, uuid, numberOfVenues = 1, startDate, numberOfDays = 3 }: NearbyShowtimesInput,
  version?: string,
): Promise<NearbyShowtimesResponse & { theatricalSlug: string }> => {
  const NearbyShowtimesApi = createTheatricalMonolithRestApi(version)
  const formattedStartDate = format(new Date(startDate || new Date()), 'yyyy-MM-dd')
  const secret = process.env.NEARBY_SHOWTIMES_API_SECRET

  if (!secret) {
    throw new Error('NEARBY_SHOWTIMES_API_SECRET is missing, make sure this request is made from the server')
  }

  try {
    const res = await NearbyShowtimesApi.get<NearbyShowtimesResponse>(
      `/user-showtime-data/${uuid}?theatrical_slug=${theatricalSlug}&number_of_venues=${numberOfVenues}&start_date=${formattedStartDate}&number_of_days=${numberOfDays}`,
      { headers: { Authorization: `Basic ${secret}` } },
    )

    return { ...res.data, theatricalSlug }
  } catch (error) {
    // Current implementation returns a 404 if no showtimes are found,
    // so catching that and returning an empty array
    if (
      axios.isAxiosError(error) &&
      error.response?.status === 404 &&
      error.response?.data?.error === 'showtime data not found'
    ) {
      return { venues: [], theatricalSlug }
    }

    throw error
  }
}

export const getNearbyShowtimesViaBFF = async ({
  theatricalSlug,
  uuid,
  numberOfVenues = 1,
  startDate,
  numberOfDays = 3,
}: NearbyShowtimesInput): Promise<NearbyShowtimesResponse & { theatricalSlug: string }> => {
  const params = {
    uuid,
    theatrical_slug: theatricalSlug,
    number_of_venues: numberOfVenues,
    start_date: startDate,
    number_of_days: numberOfDays,
  }

  const url = new URL(`${process.env.NEXT_PUBLIC_BFF_API_URL}/nearby-showtimes`)

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) url.searchParams.append(key, String(value))
  })

  const res = await axios.get<NearbyShowtimesResponse & { theatricalSlug: string }>(url.toString())
  return res.data
}
