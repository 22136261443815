import { useCallback, useEffect, useRef } from 'react'
import { camelCase } from 'lodash'
import { useSafeTrack } from '@/utils/analytics'
import { EcommFunnel } from './ecommerce-events'

export type PromotionName = 'Guild Tickets'

// Taken from Segment's promo schema: https://segment.com/docs/connections/spec/ecommerce/v2/#promotion-viewed
export interface Promotion {
  promotion_id: string
  name: PromotionName
  creative: string
  position: string
}

export interface PromotionPayload extends Omit<Promotion, 'promotion_id'> {
  funnel: EcommFunnel
  project_slug: string
}

const getPromotionId = (payload: PromotionPayload) => camelCase(`${payload.project_slug}-${payload.name}`)

const defaultOptions = {
  shouldTrackViewed: true,
}

export const usePromotionEvents = (payload: PromotionPayload, options = defaultOptions) => {
  const track = useSafeTrack()

  const viewedPromotion = useCallback(
    (overrides?: Partial<PromotionPayload>) => {
      const resolvedPayload = {
        ...payload,
        ...overrides,
        promotion_id: getPromotionId({ ...payload, ...overrides }),
        platform: 'angel-web',
      }
      track('Promotion Viewed', resolvedPayload)
    },
    [track, payload],
  )

  const clickedPromotion = useCallback(
    (overrides?: Partial<PromotionPayload>) => {
      const resolvedPayload = {
        ...payload,
        ...overrides,
        promotion_id: getPromotionId({ ...payload, ...overrides }),
        platform: 'angel-web',
      }
      track('Promotion Clicked', resolvedPayload)
    },
    [track, payload],
  )

  const viewedPromotionRef = useRef(viewedPromotion)
  useEffect(() => {
    viewedPromotionRef.current = viewedPromotion
  }, [viewedPromotion])

  useEffect(() => {
    if (options.shouldTrackViewed) {
      viewedPromotionRef.current()
    }
  }, [options.shouldTrackViewed])

  return {
    viewedPromotion,
    clickedPromotion,
  }
}
