import { useEffect, useState } from 'react'
import { CaptionSM, type TextProps } from '@/atoms/Text/Text'

interface Props {
  text: string
  color?: TextProps['color']
  className?: string
  typingSpeed?: number
  startDelay?: number
  onComplete?: () => void
}

const getCurrentWord = (text: string, currentIndex: number): string => {
  // Find the start of the current word
  let startIndex = currentIndex
  while (startIndex > 0 && text[startIndex - 1] !== ' ') {
    startIndex--
  }

  // Find the end of the current word
  let endIndex = currentIndex
  while (endIndex < text.length && text[endIndex] !== ' ') {
    endIndex++
  }

  return text.slice(startIndex, endIndex)
}

export const TypingText: React.FC<Props> = ({
  text,
  color,
  className,
  typingSpeed = 30,
  startDelay = 300,
  onComplete,
}) => {
  interface DisplayState {
    text: string
    extraSpaces: string
  }

  const [displayState, setDisplayState] = useState<DisplayState>({ text: '', extraSpaces: '' })
  const [startTyping, setStartTyping] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setStartTyping(true), startDelay)
    return () => clearTimeout(timer)
  }, [startDelay])

  useEffect(() => {
    if (!startTyping) return

    if (displayState.text.length < text.length) {
      const timer = setTimeout(() => {
        const currentWord = getCurrentWord(text, displayState.text.length)
        const nextChar = text[displayState.text.length]

        // If we're at a space, just add it without any padding
        if (nextChar === ' ') {
          setDisplayState({ text: displayState.text + ' ', extraSpaces: '' })
          return
        }

        // If we're starting a new word (after a space or at the start)
        if (displayState.text.endsWith(' ') || displayState.text === '') {
          const padding = '_'.repeat(currentWord.length - 1) // -1 because we're adding the first char
          setDisplayState({ text: displayState.text + nextChar, extraSpaces: padding })
          return
        }

        // If we're in the middle of a word, maintain the existing padding but remove one character
        const lastSpaceIndex = displayState.text.lastIndexOf(' ')
        const currentWordStart = lastSpaceIndex === -1 ? 0 : lastSpaceIndex + 1
        const currentTypedLength = displayState.text.length - currentWordStart
        const remainingCharsInWord = currentWord.length - currentTypedLength - 1 // -1 because we're adding a char

        setDisplayState({
          text: displayState.text + nextChar,
          extraSpaces: '_'.repeat(remainingCharsInWord),
        })
      }, typingSpeed)
      return () => clearTimeout(timer)
    } else if (onComplete) {
      onComplete()
    }
  }, [displayState, text, typingSpeed, startTyping, onComplete])

  return (
    <div className="relative w-full">
      <CaptionSM color={color} className={`${className} text-pre-wrap`} style={{ visibility: 'hidden' }}>
        {text}
      </CaptionSM>
      <CaptionSM color={color} className={`${className} text-pre-wrap absolute inset-0`}>
        {displayState.text}
        <span className="text-transparent">{displayState.extraSpaces}</span>
      </CaptionSM>
    </div>
  )
}
