import { useEffect } from 'react'

const msToTry = 10000
const checkInterval = 50
const maxAttempts = msToTry / checkInterval

export const useScrollToHash = (delay: number = 1) => {
  useEffect(() => {
    if (!window) return

    const id = window.location.hash?.replace('#', '')
    if (!id) return

    let attempts = 0
    const interval = setInterval(() => {
      const element = document.getElementById(id)
      if (element) {
        clearInterval(interval)
        setTimeout(() => {
          element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
        }, delay)
      } else {
        attempts++
        if (attempts > maxAttempts) {
          clearInterval(interval)
        }
      }
    }, checkInterval)

    return () => clearInterval(interval)
  }, [delay])
}
