import { useCallback, useEffect, useRef, useState } from 'react'
import { getDay } from 'date-fns'
import { Durations } from '@/constants/durations'
import { NearbyShowtimesResponse } from '@/services/GuildReleasesService/TheatricalReleaseService'
import { getTheatricalLandingReleases } from '@/services/Theatrical/queries'
import { getObjectFromLocalStorage, writeToLocalStorage } from '@/utils/local-storage'
import { logger } from '@/utils/logging'

interface CacheEntry<T> {
  data: T
  timestamp: number
}

export function useData<T>({
  key,
  fetcher,
  defer = false,
}: {
  key: string
  fetcher: () => Promise<T>
  defer?: boolean
}) {
  const [state, setState] = useState<{ data: T | undefined | null; loading: boolean; error: Error | null }>({
    data: undefined,
    loading: true,
    error: null,
  })
  const fetchingRef = useRef(false)

  const fetcherRef = useRef(fetcher)
  useEffect(() => {
    fetcherRef.current = fetcher
  }, [fetcher])

  const getCachedData = useCallback(() => {
    try {
      const cachedEntry = getObjectFromLocalStorage<CacheEntry<T>>(key)
      if (!cachedEntry) return null

      if (Date.now() - cachedEntry.timestamp > Durations.ONE_HOUR_IN_MILLISECONDS) {
        return null
      }

      return cachedEntry.data
    } catch {
      return null
    }
  }, [key])

  const setCachedData = useCallback(
    (data: T) => {
      const entry: CacheEntry<T> = {
        data,
        timestamp: Date.now(),
      }
      writeToLocalStorage(key, entry)
    },
    [key],
  )

  useEffect(() => {
    const fetchData = async () => {
      if (fetchingRef.current) return
      fetchingRef.current = true

      try {
        const cachedData = getCachedData()
        if (cachedData) {
          setState({ data: cachedData, loading: false, error: null })
          return
        }

        const res = await fetcherRef.current()
        setState({ data: res, loading: false, error: null })
        try {
          setCachedData(res)
        } catch (err) {
          logger().error('Failed to cache nearby showtimes', err)
        }
      } catch (err) {
        setState({ data: null, loading: false, error: err instanceof Error ? err : new Error('Unknown error') })
      } finally {
        fetchingRef.current = false
      }
    }

    if (!defer) {
      fetchData()
    }
  }, [defer, getCachedData, setCachedData])

  return state
}

type ShowtimesByDay = NearbyShowtimesResponse['venues'][number]['showtimes_by_day']

export const normalizeDate = (date: string) => {
  const [year, month, day] = date.split('-').map(Number)
  return new Date(year, month - 1, day)
}

export const findFirstWeekendShowtime = (showtimesByDay: ShowtimesByDay) => {
  return showtimesByDay.find((d) => {
    const normalizedDate = normalizeDate(d.date)
    const dayOfWeek = getDay(normalizedDate)
    // 5 = Friday, 6 = Saturday, 0 = Sunday
    return dayOfWeek === 5 || dayOfWeek === 6 || dayOfWeek === 0
  })
}

export const findClosestToEveningShowtime = (showtimes: ShowtimesByDay[number]['showtimes']) => {
  const getMinutes = (timeStr: string) => {
    const [time, period] = timeStr.split(' ')
    const [hoursStr, minutes] = time.split(':').map(Number)
    let hours = hoursStr

    // Convert to 24 hour format
    if (period === 'PM' && hours !== 12) hours += 12
    if (period === 'AM' && hours === 12) hours = 0

    return hours * 60 + minutes
  }

  const targetTime = 17 * 60 // 5:00pm
  return showtimes.reduce((closest, current) => {
    const currentMinutes = getMinutes(current.local_start_time_friendly)
    const closestMinutes = getMinutes(closest.local_start_time_friendly)
    const currentDiff = Math.abs(currentMinutes - targetTime)
    const closestDiff = Math.abs(closestMinutes - targetTime)

    // If the differences are equal, prefer the earlier time
    if (currentDiff === closestDiff) {
      return currentMinutes < closestMinutes ? current : closest
    }
    return currentDiff < closestDiff ? current : closest
  }, showtimes[0])
}

export const isLiveThisWeekend = (
  release: NonNullable<Awaited<ReturnType<typeof getTheatricalLandingReleases>>>[number] | null | undefined,
) => {
  const releaseDate = release?.region?.releaseDate
  const isLiveOrPreSales = release?.region?.ticketsStatus === 'LIVE' || release?.region?.ticketsStatus === 'PRE_SALES'
  if (!releaseDate || !isLiveOrPreSales) return false
  const today = new Date()
  const releaseDay = new Date(releaseDate)

  // Get next Sunday (end of weekend)
  const endOfWeekend = new Date(today)
  const daysUntilSunday = (7 - endOfWeekend.getDay()) % 7
  endOfWeekend.setDate(endOfWeekend.getDate() + daysUntilSunday)
  endOfWeekend.setHours(23, 59, 59, 999) // End of Sunday

  return releaseDay <= endOfWeekend
}
