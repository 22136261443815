import { useMemo } from 'react'
import { getWebClient } from '@/services/ApolloClient'
import { getNearbyShowtimesViaBFF } from '@/services/GuildReleasesService/TheatricalReleaseService'
import { getTheatricalLandingReleases } from '@/services/Theatrical/queries'
import { useUser } from '@/services/UserService'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { useGuildTickets } from '@/views/TicketCheckoutViews/hooks/useGuildTickets'
import { DismissableNearbyShowtimesAlert } from './DismissableNearbyShowtimesCard'
import {
  findClosestToEveningShowtime,
  findFirstWeekendShowtime,
  isLiveThisWeekend,
  normalizeDate,
  useData,
} from './utils'

export const WeekendShowtimesAlert = () => {
  const client = getWebClient()
  const { locale } = useLocale()
  const { user } = useUser()
  const { t } = useTranslate('common')

  const { data: releases } = useData({
    key: 'theatrical-releases-4',
    fetcher: () => getTheatricalLandingReleases({ client, locale }),
    defer: !user,
  })

  const targetRelease = useMemo(() => {
    const featured = releases?.find((release) => release?.isFeaturedRelease && isLiveThisWeekend(release))
    if (featured) return featured
    return releases?.find((release) => isLiveThisWeekend(release))
  }, [releases])

  const { discountCodes = [] } = useGuildTickets(targetRelease?.theatricalSlug ?? '')

  const { data: nearbyShowtimes } = useData({
    key: `nearby-showtimes-${targetRelease?.theatricalSlug}-${user?.uuid}-4`,
    fetcher: () =>
      getNearbyShowtimesViaBFF({
        theatricalSlug: targetRelease?.theatricalSlug ?? '',
        uuid: user?.uuid ?? '',
        numberOfDays: 7,
        numberOfVenues: 3,
      }),
    defer: discountCodes.length === 0,
  })

  const { venue, date, showtime } = useMemo(() => {
    if (!nearbyShowtimes?.venues?.[0]) return { venue: null, date: null, showtime: null }
    const venueWithWeekendShowtime = nearbyShowtimes.venues.find((venue) =>
      findFirstWeekendShowtime(venue.showtimes_by_day),
    )
    if (!venueWithWeekendShowtime) return { venue: null, date: null, showtime: null }
    const weekendShowtimeDay = findFirstWeekendShowtime(venueWithWeekendShowtime.showtimes_by_day)
    if (!weekendShowtimeDay) return { venue: null, date: null, showtime: null }
    return {
      venue: venueWithWeekendShowtime,
      date: normalizeDate(weekendShowtimeDay.date),
      showtime: findClosestToEveningShowtime(weekendShowtimeDay.showtimes),
    }
  }, [nearbyShowtimes])

  if (venue && date && showtime) {
    return (
      <DismissableNearbyShowtimesAlert
        name={targetRelease?.title ?? ''}
        poster={targetRelease?.verticalPoster ?? ''}
        discountCodes={discountCodes as [string, ...string[]]}
        theatricalSlug={targetRelease?.theatricalSlug ?? ''}
        venue={venue}
        date={date}
        showtime={showtime}
        prompt={t(
          'weFoundNearbyShowtimesForGuildTicketsThisWeekend',
          'We found nearby showtimes for your Guild tickets this weekend. Would you like us to book them for you?',
        )}
      />
    )
  }

  return null
}
