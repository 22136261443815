import { useMemo } from 'react'
import { isBefore } from 'date-fns'
import { getWebClient } from '@/services/ApolloClient'
import { getNearbyShowtimesViaBFF } from '@/services/GuildReleasesService/TheatricalReleaseService'
import { getTheatricalLandingReleases } from '@/services/Theatrical/queries'
import { useUser } from '@/services/UserService'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { useGuildTickets } from '@/views/TicketCheckoutViews/hooks/useGuildTickets'
import { DismissableNearbyShowtimesAlert } from './DismissableNearbyShowtimesCard'
import { findClosestToEveningShowtime, normalizeDate, useData } from './utils'

export const FeaturedShowtimesAlert = () => {
  const client = getWebClient()
  const { locale } = useLocale()
  const { user } = useUser()
  const { t } = useTranslate('common')

  const { data: releases } = useData({
    key: 'theatrical-releases-4',
    fetcher: () => getTheatricalLandingReleases({ client, locale }),
    defer: !user,
  })

  const targetRelease = useMemo(() => {
    return releases?.find((release) => release?.isFeaturedRelease)
  }, [releases])

  const { discountCodes = [] } = useGuildTickets(targetRelease?.theatricalSlug ?? '')

  const { data: nearbyShowtimes } = useData({
    key: `nearby-showtimes-${targetRelease?.theatricalSlug}-${user?.uuid}-4`,
    fetcher: () =>
      getNearbyShowtimesViaBFF({
        theatricalSlug: targetRelease?.theatricalSlug ?? '',
        uuid: user?.uuid ?? '',
        startDate: isBefore(new Date(), new Date(targetRelease?.region?.releaseDate ?? ''))
          ? targetRelease?.region?.releaseDate
          : undefined,
      }),
    defer: discountCodes.length === 0,
  })

  const { venue, date, showtime } = useMemo(() => {
    try {
      const venue = nearbyShowtimes?.venues?.[0]

      return {
        venue,
        date: normalizeDate(venue?.showtimes_by_day?.[0]?.date ?? ''),
        showtime: findClosestToEveningShowtime(venue?.showtimes_by_day?.[0]?.showtimes ?? []),
      }
    } catch (error) {
      return { venue: null, date: null, showtime: null }
    }
  }, [nearbyShowtimes])

  if (venue && date && showtime) {
    return (
      <DismissableNearbyShowtimesAlert
        name={targetRelease?.title ?? ''}
        poster={targetRelease?.verticalPoster ?? ''}
        discountCodes={discountCodes as [string, ...string[]]}
        theatricalSlug={targetRelease?.theatricalSlug ?? ''}
        venue={venue}
        date={date}
        showtime={showtime}
        prompt={t(
          'weFoundNearbyShowtimesForFreeGuildTickets',
          'We found nearby showtimes for your free guild tickets. Would you like us to book them for you?',
        )}
        heading={t(
          'bookingYourSeatsTodayHelpsFilmSucceed',
          'Booking your seats today helps this film succeed in theaters!',
        )}
      />
    )
  }

  return null
}
