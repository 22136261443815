import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const ClockIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'white', className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.00016 14.6668C7.07424 14.6668 6.20708 14.491 5.39868 14.1394C4.58979 13.7873 3.88609 13.3118 3.28757 12.7128C2.68856 12.1142 2.213 11.4105 1.8609 10.6016C1.5093 9.79325 1.3335 8.92609 1.3335 8.00016C1.3335 7.07424 1.5093 6.20683 1.8609 5.39794C2.213 4.58955 2.68856 3.88584 3.28757 3.28683C3.88609 2.68831 4.58979 2.21325 5.39868 1.86164C6.20708 1.50955 7.07424 1.3335 8.00016 1.3335C8.92609 1.3335 9.7935 1.50955 10.6024 1.86164C11.4108 2.21325 12.1145 2.68831 12.7135 3.28683C13.312 3.88584 13.7873 4.58955 14.1394 5.39794C14.491 6.20683 14.6668 7.07424 14.6668 8.00016C14.6668 8.92609 14.491 9.79325 14.1394 10.6016C13.7873 11.4105 13.312 12.1142 12.7135 12.7128C12.1145 13.3118 11.4108 13.7873 10.6024 14.1394C9.7935 14.491 8.92609 14.6668 8.00016 14.6668ZM7.25942 5.0372V7.98164C7.25942 8.08041 7.27794 8.17597 7.31498 8.26831C7.35201 8.36115 7.40757 8.44461 7.48164 8.51868L9.57424 10.6113C9.71004 10.7471 9.87671 10.815 10.0742 10.815C10.2718 10.815 10.4446 10.7409 10.5928 10.5928C10.7286 10.457 10.7965 10.2841 10.7965 10.0742C10.7965 9.86436 10.7286 9.69152 10.5928 9.55572L8.7409 7.70387V5.01868C8.7409 4.80881 8.67004 4.63597 8.52831 4.50016C8.38609 4.36436 8.21004 4.29646 8.00016 4.29646C7.79029 4.29646 7.61448 4.36732 7.47276 4.50905C7.33053 4.65127 7.25942 4.82732 7.25942 5.0372ZM8.00016 13.1853C9.44461 13.1853 10.6698 12.6821 11.6757 11.6757C12.6821 10.6698 13.1853 9.44461 13.1853 8.00016C13.1853 6.55572 12.6821 5.33029 11.6757 4.32387C10.6698 3.31794 9.44461 2.81498 8.00016 2.81498C6.55572 2.81498 5.33053 3.31794 4.32461 4.32387C3.31819 5.33029 2.81498 6.55572 2.81498 8.00016C2.81498 9.44461 3.31819 10.6698 4.32461 11.6757C5.33053 12.6821 6.55572 13.1853 8.00016 13.1853Z"
        fill={getHexColor(color)}
      />
    </svg>
  )
}
