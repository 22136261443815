import { useCallback } from 'react'
import { format } from 'date-fns'
import { LinkButton } from '@/atoms/Button'
import { CalendarIcon } from '@/atoms/Icons/CalendarIcon'
import { ClockIcon } from '@/atoms/Icons/ClockIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { Image } from '@/atoms/Image'
import { CaptionSM, TitleSM, TitleXS } from '@/atoms/Text'
import { paths } from '@/constants'
import { useToast } from '@/molecules/Toast'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useSafeTrack } from '@/utils/analytics'
import { usePromotionEvents } from '@/utils/analytics/promotion-events'
import { useTranslate } from '@/utils/translate/translate-client'

type Props = {
  name: string
  poster: string
  theatricalSlug: string
  venue: {
    name: string
    address_text: string
  }
  date: Date
  showtime: {
    local_start_time_friendly: string
    ticket_url: string
  }
  handleDismiss: () => void
}

export const NearbyShowtimesCard: React.FC<Props> = ({
  date,
  showtime,
  venue,
  name,
  poster,
  theatricalSlug,
  handleDismiss,
}) => {
  const { t } = useTranslate('common')
  const { showToast } = useToast()
  const track = useSafeTrack()

  const { clickedPromotion } = usePromotionEvents({
    name: 'Guild Tickets',
    creative: 'nearby_showtimes_alert',
    position: 'banner_bottom',
    project_slug: theatricalSlug,
    funnel: 'theatrical',
  })

  const clickedNotNearYou = useCallback(() => {
    track('User Saw Incorrect Nearby Showtimes', {
      theatricalSlug,
      venue_name: venue.name,
      venue_address: venue.address_text,
    })
    handleDismiss()
    showToast(t('thanksForFeedback', 'Thanks for the feedback!'), {
      showInMillis: 10000,
    })
  }, [showToast, track, theatricalSlug, handleDismiss, venue.name, venue.address_text, t])

  return (
    <div className="flex w-full flex-col items-start justify-between gap-3">
      <div className="flex gap-3">
        <div className="relative w-[70px]">
          {poster && <Image src={poster} alt={name} width={140} height={212} className="rounded-lg" />}
          <Image
            src={getCloudinaryImageUrl({ path: '/v1739288660/angel-studios/guild/i62oj6hfnhwg0il46umx', width: 112 })}
            alt="Angel Projects"
            width={112}
            height={126}
            className="absolute left-[7px] top-[70px] w-[56px]"
          />
        </div>
        <div className="flex flex-1 flex-col text-left">
          <TitleSM color="white" weight="semibold" className="mb-1 line-clamp-1">
            {name}
          </TitleSM>
          <TitleXS color="white" className="mb-1 line-clamp-1">
            {venue.name}
          </TitleXS>
          <CaptionSM color="core-gray-400" className="line-clamp-2">
            {venue.address_text}
          </CaptionSM>
          <CaptionSM color="white" className="mt-1">
            {t('notNearYou', 'Not near you?')}{' '}
            <span className="cursor-pointer text-core-oxide underline active:opacity-75" onClick={clickedNotNearYou}>
              {t('letUsKnow', 'Let us know!')}
            </span>
          </CaptionSM>

          <div className="mt-2 flex items-center gap-2">
            <div className="flex items-center gap-1 rounded-full border border-core-gray-700/50 px-2 py-1">
              <CalendarIcon color="core-gray-400" className="h-4 w-4" />
              <CaptionSM color="core-gray-400">{format(date, 'EEE, MMM d')}</CaptionSM>
            </div>
            <div className="flex items-center gap-1 rounded-full border border-core-gray-700/50 px-2 py-1">
              <ClockIcon color="core-gray-400" className="h-4 w-4" />
              <CaptionSM color="core-gray-400">{showtime.local_start_time_friendly}</CaptionSM>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full gap-2 pl-[70px]">
        <LinkButton
          href={showtime.ticket_url}
          onClick={() => clickedPromotion()}
          variant="white"
          className="ml-3 flex flex-1 items-center gap-1 !px-0 text-xs"
        >
          <TicketIcon className="h-4 w-4" color="black" />
          {t('bookSeats', 'Book Seats')}
        </LinkButton>
        <LinkButton
          href={paths.tickets.checkout.showtimes(theatricalSlug)}
          variant="plain"
          className="flex-1 !border-core-gray-700 !bg-core-gray-700 px-0 text-xs !text-white"
        >
          {t('moreShowtimes', 'More Showtimes')}
        </LinkButton>
      </div>
    </div>
  )
}
